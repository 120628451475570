import React, { useState, useEffect, useCallback, useRef } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import LocationMap from '../components/Gmaps';
import { PropertyBlockV2 } from './PropertyBlock/PropertyBlockV2';
import moment from 'moment';
//settings
import API from '../utils/API';
import translate from '../components/languages';
import { useHistory, useLocation } from 'react-router-dom';
import Helper, { Variables } from '../utils/Helper';
import { getAllFavoritesProperties } from '../utils/FavoriteProperties';
import { ToastConfig } from '../utils/ToastConfig';
import { SkeletonsContainer } from './PropertySkeleton/SkeletonsContainer/SkeletonsContainer';
import { TRecentParams } from './../utils/filter/FilterContainer.tsx';
import FilterBox, {
  FilterKeysNames,
  prepareStrParams,
} from '../utils/filter/FilterContainer';
import { PaginationMUI } from './Pagination/PaginationMUI';
import '../components/componentStyles/FilterCheckOutStyle.css';
import '../components/componentStyles/PropertiesStyle.scss';
import '../components/componentStyles/image-gallery.scss';
import GmapLoading, {
  GeneralLoading,
  SpinnerLoading,
} from '../components/GmapLoading';
import useProperties from '../utils/hooks/useProperties';
import { HELPER_TSX } from '../utils/HelperTSX';
import { isMobile } from 'react-device-detect';

export const propsPerPage = 10;

const hasSpecialFilters = (params) => {
  let response = false;
  if (
    params.minimumBathrooms ||
    params.minimumBedrooms ||
    params.typeProperties
  ) {
    response = true;
  }
  return response;
};

const hasToShowFavorites = (locationParams) => {
  return (
    locationParams &&
    locationParams.state !== undefined &&
    locationParams.state.kindOfProperties === FilterKeysNames.favorites
  );
};

function ScreenProperties() {
  let history = useHistory();
  const location = useLocation();
  const [cantDaysToBook, setCantDaysToBook] = useState(0);
  const [filters, setFilters] = useState(HELPER_TSX.getRecentParams(location));
  const [showFavs, setShowFavs] = useState(false);
  const {
    propertiesList,
    loading: loadingProperties,
    totalProperties,
  } = useProperties(filters);

  const parentDivRef = useRef(null);
  const childDivRef = useRef(null);

  const getProperties = useCallback(async (locationParams) => {
    let params = HELPER_TSX.getRecentParams(locationParams);
    console.log('getProperties in Properts.js');
    setFilters(params);

    //limit to max if hasSpecialFilters cause filter also in frontend
    if (hasToShowFavorites(locationParams)) {
      console.log('has to show fav props');
      setShowFavs(true);
    } else {
      setShowFavs(false);
    }
  }, []);

  useEffect(() => {
    const element = document.getElementById('filterContainerBlock');
    const extraFilters = document.getElementById('extraFiltersContainer');
    const handleScroll = () => {
      if (parentDivRef.current.scrollTop > 150) {
        element.style.position = 'sticky';
        element.style.top = '0';
        element.style.zIndex = '999';
        extraFilters.style.display = 'none';
      } else {
        element.style.position = 'initial';
        element.style.top = '0';
        element.style.zIndex = '0';
        extraFilters.style.display = 'initial';
      }
    };

    const container = parentDivRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    // Cleanup event listener on component unmount
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    console.log('changed filter in parent');
    getProperties(location);
  }, [location.search]);

  const sendToSearch = (strParams, params) => {
    console.log('cambia filtros desde filter container');

    if (params.kindOfProperties === FilterKeysNames.regular) {
      let removeFeatured = params.customAmenities
        ? params.customAmenities
            .replace(',featured', '')
            .replace('featured', '')
        : null;
      params.customAmenities = removeFeatured;
    }
    history.push('/properties?' + strParams, params);
  };

  const handlePaginationChange = (event, value) => {
    console.log('triggered handlePaginationChange');
    setFilters({
      ...filters,
      page: value,
    });

    let strParams = prepareStrParams(filters);
    strParams += '&page=' + value;
    sendToSearch(strParams, filters);
  };

  const containerPropsClass = isMobile
    ? 'container-lg'
    : 'container-lg d-flex flex-column pt-0';

  console.log('properties list', propertiesList);
  return (
    <div className="layout-container ">
      <div className="layout-navbar">
        <NavBar altLogo={true} />
        <div className="navbar-background"></div>
      </div>
      <div className="layout-component d-flex ">
        <div className={containerPropsClass}>
          <div
            className="container_properties_and_map"
            style={propertiesList?.length === 0 ? { flexWrap: 'nowrap' } : null}
          >
            <div ref={parentDivRef} className="filter-map-container scroll-fix">
              <div className="total-props-cont" id="elementToScroll">
                <span className="prop-quantity-text">{totalProperties}</span>{' '}
                <span className="prop-text">
                  {translate.t('propertyList.searchResultTitle')}
                </span>
              </div>
              <FilterBox
                shadow={false}
                sendFilters={sendToSearch}
                showFavorite={true}
                showClearFilter={
                  location && location.search.length ? true : false
                }
                inPropList={true}
                showFeatured={true}
                totalProperties={totalProperties}
              />

              <PropertiesList
                loading={loadingProperties}
                filters={filters}
                propertiesList={propertiesList}
              />
            </div>
            <div className="container_map">
              {(loadingProperties || !propertiesList.length) && (
                <div className="loading-map">
                  <GmapLoading />
                </div>
              )}

              {!loadingProperties && propertiesList?.length > 0 && (
                <LocationMap
                  cantDaysToBook={cantDaysToBook}
                  filters={filters}
                  properties={propertiesList}
                />
              )}
              <div className="green-bar-mobile"></div>
            </div>
          </div>
          <div className="pagination_container">
            <div className="col-md-12 pagination-dock">
              <PaginationMUI
                showPagination={true}
                pagesToShow={Math.ceil(totalProperties / propsPerPage)}
                totalProperties={totalProperties}
                handleChange={handlePaginationChange}
                page={filters.page}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ScreenProperties;

function PropertiesList({ loading, propertiesList, filters }) {
  if (loading) return <SkeletonsContainer />;

  return (
    <div className="col-md-12 p-0 d-flex flex-wrap props-absolute-container container_properties_list">
      {propertiesList?.map((eachProperty, k) => {
        const keyMap = eachProperty.uid + k;
        return (
          <div key={keyMap} className="col-md-6 col-sm-12 prop-cont">
            <PropertyBlockV2 filters={filters} property={eachProperty} />
          </div>
        );
      })}
    </div>
  );
}
