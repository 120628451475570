import { AuthWelcome } from './AuthService';
import { TRecentParams } from './filter/FilterContainer';

const Auth = new AuthWelcome();

export const API_WELCOME = {
  getProperties: function (params: TRecentParams) {
    return Auth.get('properties', params);
  },
  getNeighborhoods: function () {
    return Auth.get('properties/neighborhoods', {});
  },
};

export default API_WELCOME;
