import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Modal, Col, Form, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import bath from '../../../src/assets/11. FICHA/bath.svg';
import dorm from '../../../src/assets/11. FICHA/dorm.svg';
import guests from '../../../src/assets/11. FICHA/guests.svg';
import home from '../../../src/assets/11. FICHA/home.svg';
import icon360 from '../../assets/11. FICHA/360.svg';
import icon1 from '../../assets/11. FICHA/cleaning.svg';
import icon2 from '../../assets/11. FICHA/cleaning2.svg';
import icon3 from '../../assets/11. FICHA/recipt.svg';
import Footer from '../../components/Footer';
import LocationMap from '../../components/Gmaps';
import NavBar from '../../components/NavBar';
import '../../components/componentStyles/CarouselCheckStyle.css';
import '../../components/componentStyles/CheckOutStyle.scss';
import '../../components/componentStyles/calendarHostfully.scss';
import translate from '../../components/languages';
import API from '../../utils/API';
import Helper, { Variables } from '../../utils/Helper';
import CheckoutCarousel from './CheckoutCarousel';
import { amenities } from './amenities';
import { camelize } from './utils';

import visa from '../../assets/11. FICHA/visa.png';
import master from '../../assets/11. FICHA/master.svg';
import american from '../../assets/11. FICHA/american.svg';
import paypal from '../../assets/11. FICHA/paypal.svg';
import washer from '../../assets/11. FICHA/washer.svg';
import tv from '../../assets/11. FICHA/tv.svg';
import wifi from '../../assets/11. FICHA/wifi.svg';
import ac from '../../assets/11. FICHA/ac.svg';
import xbutton from '../../assets/11. FICHA/xbutton.svg';
import 'react-alice-carousel/lib/alice-carousel.css';
import Carousel from './Carousel';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';

import Loader from '../../utils/carousel/Loader';
import useProperties from '../../utils/hooks/useProperties';
import { AMENITIES_LIST } from '../../utils/amenities/amenities';
import { ModalContactProperty } from './ModalContact';

const scriptCalendar = `/assets/js/calendarHostfully.js`;
const scriptCapture = `/assets/js/leadCaptureWidget_2.0.js`;
const scriptOverrideCapture = `/assets/js/overrideLeadCaptureFinal.js`;
const scriptPicker = `/assets/js/pikaday.js`;
const heightIconCheckOut = 35;

function CheckoutScreen() {
  const location = useLocation();
  const [isFirstLoad, setFirstLoad] = useState(true);
  const [property, setProperty] = useState([]);
  const [bedTypes, setBedTypes] = useState([]);
  const [photosList, setPhotos] = useState([]);
  const [photosRoomList, setPhotosRoom] = useState([]);
  const [purePhotosRoomArray, setPurePhotosArray] = useState([]);
  const [amenitiesList, setAmenities] = useState([]);
  const [propertyDescription, setDescription] = useState({});
  const [reco360, setReco360] = useState(null);
  const [showModalContactProperty, setShowModal] = useState(false);
  const [checkoutGuest] = useState(Helper.getParam('guests', location));
  const [checkoutCheckIn] = useState(Helper.getParam('checkIn', location));
  const [checkoutCheckOut] = useState(Helper.getParam('checkOut', location));
  const [name] = useState('');
  const [lastName] = useState('');
  const [email] = useState('');
  const [photosV3, setPhotosV3] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { propertiesList, loading: loadingProperty } = useProperties({
    uid: Helper.getParam('propertyId', location),
  });

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    let propertyId =
      location.state !== undefined && location.state.propertyId.propertyId
        ? location.state.propertyId
        : Helper.getParam('propertyId', location);

    if (isFirstLoad) {
      API.Web.getPropertyById({}, propertyId).then(async (response) => {
        let newObj = {
          ...response,
          address: {
            latitude: response.latitude,
            longitude: response.longitude,
          },
        };

        setProperty(newObj);
      });
      API.Web.getPropertyBedTypes({ propertyUid: propertyId }).then(
        (response) => setBedTypes(response),
      );
      API.Web.getPropertyDescription({ propertyUid: propertyId }).then(
        (response) => setDescription(findDescriptionByLanguage(response)),
      );
      API.Web.getPropertyCustomAmenities({ propertyUid: propertyId }).then(
        (response) => {
          setReco360(getReco360(response));
        },
      );
      API.Web.getPhotosById({ propertyUid: propertyId }).then((photos) => {
        setPhotos(photos);
        const roomPhotos = photos.filter((each) => each.airbnbRoomId != null);
        const purePics = [];
        const pics = roomPhotos.map((each) => {
          purePics.push({ ...each });

          return (
            <a
              href={each.url}
              className="d-flex flex-column align-items-start checkout-custom-carousel"
            >
              <img
                src={each.url}
                className="checkout-bed-pic"
                alt="bed images"
              />
              <span className="checkout-bed-title">
                {translate.t('checkout.beedroom').toString()}
              </span>
            </a>
          );
        });
        setPurePhotosArray(purePics);
        setPhotosRoom(pics);
      });
      API.Web.getPhotosV3({ propertyUid: propertyId }).then((photos) => {
        setPhotosV3(photos);
      });
      API.Web.getAmenities(propertyId).then((amenities) => {
        let amenitiesActived = Helper.filterTrueAmenities(
          Object.entries(amenities),
        );
        let amentiesParsed = parseAmenitiesName(amenitiesActived);
        setAmenities(amentiesParsed);
      });

      setFirstLoad(false);
    }
  }, [isFirstLoad]);

  const statusCalendar = useScript(scriptCalendar);

  useEffect(() => {
    if (
      statusCalendar === 'ready' &&
      property.availabilityCalendarUrl !== undefined &&
      propertyDescription.name !== undefined
    ) {
      calculateMinStayAmount(property);
      let propertyIdFriendly = property.availabilityCalendarUrl
        .split('.ics')[0]
        .split('calendar/')[1];
      new window.orbiwidget('widget34725', {
        id: propertyIdFriendly,
        showTentative: 1,
        showAvailability: true,
        monthsToDisplay: 3,
        name: propertyDescription.name,
      });
    }
  }, [statusCalendar, property, propertyDescription]);

  const statusPicker = useScript(scriptPicker);
  const statusWidgetCapture = useScript(scriptCapture);
  const scriptOverride = useScript(scriptOverrideCapture);
  useEffect(() => {
    if (
      statusWidgetCapture === 'ready' &&
      statusPicker === 'ready' &&
      property.uid !== undefined
    ) {
      const country = localStorage.getItem('country');
      var widget = new window.Widget('leadWidget', property.uid, {
        type: 'agency',
        fields: [],
        showAvailability: true,
        lang: country === 'es' || country === null ? 'ES' : 'US',
        minStay: true,
        price: true,
        emailClient: true,
        saveCookie: true,
        showDynamicMinStay: true,
        backgroundColor: '#FFFFFF',
        buttonSubmit: { backgroundColor: '#20b19d' },
        labelColor: '#20b19d',
        showDiscount: true,
        showPriceDetailsLink: true,
        includeReferrerToRequest: true,
        customDomainName: 'https://rentals.welcome2ba.com/',
        aid: 'ORB-49587220416635719',
        valuesByDefaults: {
          checkIn: { value: checkoutCheckIn },
          checkOut: { value: checkoutCheckOut },
          guests: { value: checkoutGuest },
          discountCode: { value: '' },
        },
        pathRoot: 'https://platform.hostfully.com/',
      });
    }
  }, [statusPicker, statusWidgetCapture, property]);

  function getReco360(customAmenities) {
    let filtered = customAmenities.filter(
      (each) => each.name.search('https://') !== -1,
    );
    return filtered.length ? filtered[0].name : false;
  }
  function findDescriptionByLanguage(arrayLanguages) {
    const country = localStorage.getItem('country');
    let firstLanguage = arrayLanguages[0];
    const hostufllyLang = country === 'es' ? 'es_ES' : 'en_US';

    return arrayLanguages.find((each) => each.locale === hostufllyLang);
  }

  async function calculateMinStayAmount() {
    var today = moment().format(Variables.hostfullyDateFormat);
    var todayPlus30 = moment()
      .add(30, 'days')
      .format(Variables.hostfullyDateFormat);
    let quoteParams = { propertyUid: property.uid };

    quoteParams['guestNumber'] = checkoutGuest ? checkoutGuest : 1;
    quoteParams['checkInDate'] = checkoutCheckIn ? checkoutCheckIn : today;
    quoteParams['checkOutDate'] = checkoutCheckOut
      ? checkoutCheckOut
      : todayPlus30;

    let quote = await API.Web.getBookQuote(quoteParams, property.uid);

    var date1 = moment(quoteParams['checkInDate']);
    var date2 = moment(quoteParams['checkOutDate']);
    var days = date2.diff(date1, 'days');
  }

  function parseAmenitiesName(amenities) {
    return amenities.map((each) => each[0].replace('has', ''));
  }

  function generateLead(data = false) {
    if (!data) {
      var data = {
        agencyUid: '064c3db9-f703-4609-8afc-24917b91f906',
        propertyUid: location.state.propertyId,
        checkInDate: checkoutCheckIn,
        checkOutDate: checkoutCheckOut,
        email: email,
        status: 'NEW',
        firstName: name,
        lastName: lastName,
        adultCount: checkoutGuest,
        childrenCount: 0,
        petCount: 0,
        externalBookingID: 1,
      };
    }

    API.Web.postLead(data);
  }

  function toggleModalContactProperty() {
    setShowModal(!showModalContactProperty);
  }

  const returnIconFromAmenitiesObject = (key) => {
    if (amenities[key] !== undefined) return amenities[key];
    else
      return (
        'https://rentals.welcome2ba.com/img/amenities/has' +
        camelize(key) +
        '.png'
      );
  };

  const [carouselItems, setItems] = useState([]);

  useEffect(() => {
    setItems(
      photosList.map((eachPhoto, i) => {
        return (
          <div className={`p-1`}>
            <img
              src={eachPhoto.url}
              className="checkoutSmallPic"
              alt="Loading..."
              key={i}
              style={{ pointerEvents: 'none' }}
            />
          </div>
        );
      }),
    );
  }, [photosList]);

  useEffect(() => {
    if (modalIsOpen) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'scroll';
  }, [modalIsOpen]);

  if (!property || property.length === 0) return null;

  return (
    <div>
      <NavBar altLogo={true} />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 99999999,
          },
          content: {
            width: isMobile ? '95%' : '45%',
            margin: isMobile ? 'auto' : 'auto',
            padding: isMobile ? '20px' : '40px',
            inset: isMobile ? '10px' : '40px',
          },
        }}
      >
        <div className="checkout-modal-container">
          <div className="checkout-modal-header">
            <h3 className="checkout-modal-title">Todas las comodidades</h3>
            <button className="checkout-modal-x-button" onClick={closeModal}>
              <img src={xbutton} className="x-button" alt="close button" />
            </button>
          </div>
          <br />
          <br />
          <div
            className="d-flex align-items-center"
            style={{ position: 'relative' }}
          >
            <h5
              className="checkout-modal-title"
              style={{ fontFamily: 'SharpGrotesk', fontWeight: 'bold' }}
            >
              Generales
            </h5>
            <div className="checkout-modal-line col"></div>
          </div>
          <div className="checkout-modal checkout-modal-amenities-container-5 mt-2">
            <span>Aire acondicionado</span>
            <span>Internet Wifi de alta velocidad</span>
            <span>Se permiten niños</span>
            <span>Living comedor</span>
            <span>24 checking</span>
            <span>Smart TV + TV</span>
            <span>Cable TV</span>
            <span>Limpieza con desinfectante</span>
            <span>Calefacción</span>
            <span>Planca</span>
          </div>
          <div
            className="d-flex align-items-center mt-4"
            style={{ position: 'relative' }}
          >
            <h5
              className="checkout-modal-title"
              style={{ fontFamily: 'SharpGrotesk', fontWeight: 'bold' }}
            >
              Cocina
            </h5>
            <div className="checkout-modal-line col"></div>
          </div>
          <div className="checkout-modal checkout-modal-amenities-container-4 mt-2">
            <span>Elementos de cocina + utensillos</span>
            <span>Cocina + Horno</span>
            <span>Sartenes, Ollas</span>
            <span>Tetera + Cafetera</span>
            <span>Lavavajillas</span>
            <span>Heladera + Freezer</span>
            <span>Tostadoras</span>
            <span>Copas de vino</span>
          </div>
          <div
            className="d-flex align-items-center mt-4"
            style={{ position: 'relative' }}
          >
            <h5
              className="checkout-modal-title"
              style={{ fontFamily: 'SharpGrotesk', fontWeight: 'bold' }}
            >
              Baño/s
            </h5>
            <div className="checkout-modal-line col"></div>
          </div>
          <div className="checkout-modal checkout-modal-amenities-container-2 mt-2">
            <span>Secador de pelo</span>
            <span>Toallas y Toallones</span>
            <span>Bidet</span>
            <span>Champú, acondicionador y jabón</span>
          </div>
          <div
            className="d-flex align-items-center mt-4"
            style={{ position: 'relative' }}
          >
            <h5
              className="checkout-modal-title"
              style={{
                fontFamily: 'SharpGrotesk',
                fontWeight: 'bold',
                width: 'fit-content',
              }}
            >
              Instalaciones
            </h5>
            <div className="checkout-modal-line col"></div>
          </div>
          <div className="checkout-modal checkout-modal-amenities-container-2 mt-2">
            <span>Estacionamiento Libre</span>
            <span>Lavadoras</span>
            <span>Ascensor</span>
            <span>Agua Caliente</span>
          </div>
        </div>
      </Modal>
      <div className="navbar-background"></div>
      <div className="" style={{ paddingTop: '85px' }}>
        <div className="pb-4">
          {carouselItems.length > 0 ? (
            <CheckoutCarousel
              items={carouselItems}
              pureItems={purePhotosRoomArray}
            />
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: '250px' }}
            >
              <Loader />
            </div>
          )}
        </div>

        <div className="checkout-title-reservation-container">
          <div className="checkout-cont">
            <div className="checkout-title-prop checkout-mobile-container">
              {propertyDescription.name}
            </div>
            <div className="city-check checkout-mobile-container">
              {property.city}
            </div>
            <div className="checkout-prop-summary checkout-mobile-container">
              <span className="type-check">
                <img src={home} width="5%" alt="Loading..." className="mr-2" />
                <BrowserView>{property.type}</BrowserView>
                <MobileView>
                  {property.type == 'APARTMENT' && 'APTO.'}
                </MobileView>
              </span>
              <span className="type-check ml-2">
                <img
                  src={guests}
                  width="5%"
                  alt="Loading..."
                  className="mr-2"
                />
                <BrowserView>
                  {property.baseGuests}
                  {property.baseGuests === 1
                    ? `${translate.t('guest').toUpperCase()}`
                    : `${translate.t('guests').toUpperCase()}`}
                </BrowserView>
                <MobileView>x{property.baseGuests}</MobileView>
              </span>
              <span className="type-check ml-2">
                <img src={dorm} width="5%" alt="Loading..." className="mr-2" />
                <BrowserView>
                  {property.bedrooms}
                  {property.bedrooms === 1
                    ? `${translate.t('bedroom').toUpperCase()}`
                    : `${translate.t('bedrooms').toUpperCase()}`}
                </BrowserView>
                <MobileView>x{property.bedrooms}</MobileView>
              </span>
              <span className="type-check ml-2">
                <img src={bath} width="5%" alt="Loading..." className="mr-2" />
                <BrowserView>
                  {property.bathrooms}
                  {property.bathrooms === 1
                    ? `${translate.t('bath').toUpperCase()}`
                    : `${translate.t('baths').toUpperCase()}`}
                </BrowserView>
                <MobileView>x{property.bathrooms}</MobileView>
              </span>
            </div>

            <span className="checkout-dots checkout-mobile-container">
              ● ● ●
            </span>
            <div className="row">
              <div className="px-3 w-100">
                <br />
                <div className="text-description checkout-mobile-container">
                  <p>{propertyDescription.summary}</p>
                </div>
                <MobileView>
                  <br />
                  <div className="widget-checkout-container">
                    <div className="checkout-book-now">
                      <div id="leadWidget" className=""></div>
                      <div className="card-check">
                        <div className="text-reserva">
                          <p>
                            {translate.t('onlineBooking')}{' '}
                            {translate.t('onlineBookingText')}{' '}
                            <strong style={{ color: '#009984' }}>
                              (+54) 11 2522-9682
                            </strong>
                          </p>
                          <button
                            className="button-reserva btn-outline"
                            onClick={() => {
                              toggleModalContactProperty();
                            }}
                          >
                            {translate.t('contactUs')}
                          </button>
                          {showModalContactProperty && (
                            <ModalContactProperty
                              generateLead={generateLead}
                              property={property}
                            />
                          )}
                        </div>

                        <div className="checkout-cards mt-3">
                          <img
                            src={paypal}
                            width="100%"
                            height="100%"
                            alt="Loading..."
                          />
                          <img
                            src={visa}
                            width="100%"
                            height="100%"
                            alt="Loading..."
                          />
                          <img
                            src={master}
                            width="100%"
                            height="100%"
                            alt="Loading..."
                          />
                          <img
                            src={american}
                            width="100%"
                            height="100%"
                            alt="Loading..."
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </MobileView>
                <MobileView>
                  <div
                    style={{
                      width: '25px',
                      borderBottom: '1px solid rgba(32, 177, 157)',
                      marginLeft: '30px',
                      marginBottom: '20px',
                    }}
                  ></div>
                </MobileView>

                <CheckoutAmenitiesBox
                  propertiesList={propertiesList}
                  loading={loadingProperty}
                />

                <div
                  className="checkout-mobile-container mb-4"
                  style={{ cursor: 'pointer', width: 'fit-content' }}
                  onClick={openModal}
                >
                  {amenitiesList?.length > 4 && (
                    <div
                      style={{
                        borderBottom: '1px solid black',
                        width: 'fit-content',
                      }}
                    >
                      <span className="checkout-show-all-amenities">
                        {translate.t('showAllAmenities')}
                      </span>{' '}
                      <span className="checkout-more-amenities">
                        +{amenitiesList?.length - 4}
                      </span>
                    </div>
                  )}
                </div>

                {/* //custom carousel inside if desktop, other carousel if mobile */}
                <Carousel
                  photosRoomList={photosRoomList}
                  purePhotosRoomArray={purePhotosRoomArray}
                />

                <MobileView>
                  <div
                    style={{
                      width: '25px',
                      borderBottom: '1px solid rgba(32, 177, 157)',
                      marginLeft: '30px',
                    }}
                  ></div>
                  <br />
                </MobileView>
                <div className="checkout-apart-includes-container">
                  <div className="title-description checkout-mobile-container include-title">
                    <span>{translate.t('rentInclude')}</span>
                  </div>
                  <br />
                  <div className="text-description checkout-mobile-container">
                    <p className="d-flex">
                      <img
                        src={icon1}
                        height={heightIconCheckOut}
                        className="mr-4"
                        alt="Loading..."
                        style={{ width: '40px' }}
                      />
                      <div className="d-flex flex-column align-items-start justify-content-start">
                        <span className="checkout-includes-title">
                          {translate.t('professionalClean')}
                        </span>
                        <span className="checkout-includes-content">
                          {translate.t('biweeklyReview')}
                        </span>
                      </div>
                    </p>
                  </div>
                  <div className="text-description checkout-mobile-container">
                    <p className="d-flex">
                      <img
                        src={icon2}
                        height={heightIconCheckOut}
                        className="mr-4"
                        alt="Loading..."
                        style={{ width: '40px' }}
                      />
                      <div className="d-flex flex-column align-items-start justify-content-start">
                        <span className="checkout-includes-title">
                          {translate.t('amenitiesBath')}
                        </span>
                        <span className="checkout-includes-content">
                          {translate.t('amenitiesBathText')}
                        </span>
                      </div>
                    </p>
                  </div>
                  <div className="text-description checkout-mobile-container">
                    <p className="d-flex">
                      <img
                        src={icon3}
                        height={heightIconCheckOut}
                        className="mr-4"
                        alt="Loading..."
                        style={{ width: '40px' }}
                      />
                      <div className="d-flex flex-column align-items-start justify-content-start">
                        <span className="checkout-includes-title">
                          {translate.t('services')}
                        </span>
                        <span className="checkout-includes-content">
                          {translate.t('servicesText')}
                        </span>
                      </div>
                    </p>
                  </div>

                  <MobileView>
                    <br />
                    <div
                      style={{
                        width: '25px',
                        borderBottom: '1px solid rgba(32, 177, 157)',
                        marginLeft: '30px',
                      }}
                    ></div>
                    <br />
                  </MobileView>
                  <div className="title-description d-flex align-items-center checkout-mobile-container checkout-view-360">
                    <span>View </span> <img src={icon360} width="60" />
                  </div>

                  <br />
                  {reco360 ? (
                    <div className="">
                      <div className="row">
                        <div className={`col-md-12`}>
                          <iframe
                            width="100%"
                            height="640"
                            frameborder="0"
                            allowFullScreen={true}
                            allow="xr-spatial-tracking; gyroscope; accelerometer"
                            src={reco360}
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className="mt-4 title-description checkout-mobile-container include-title">
                    <p>{translate.t('reserveProcess')}</p>
                  </div>

                  <div className="text-description checkout-mobile-container">
                    <p>
                      <span className="checkout-reserve-title">
                        {translate.t('howWork')}
                      </span>
                      <br />
                      <span
                        className="checkout-reserve-content"
                        style={{ color: 'black' }}
                      >
                        {translate.t('howWorkText')}
                      </span>
                    </p>
                  </div>
                  <div className="text-description checkout-mobile-container">
                    <span className="checkout-reserve-title">
                      {translate.t('paymentMethods')}
                    </span>
                    <br />
                    <span
                      className="checkout-reserve-content"
                      style={{ color: 'black' }}
                    >
                      {translate.t('paymentMethodsText')}
                    </span>
                  </div>
                  <br />
                  <div className="text-description checkout-mobile-container">
                    <p>
                      <span className="checkout-reserve-title">
                        {translate.t('longStays')}
                      </span>
                      <br />
                      <span
                        className="checkout-reserve-content"
                        style={{ color: 'black' }}
                      >
                        {translate.t('longStaysText')}
                      </span>
                    </p>
                  </div>

                  <BrowserView>
                    <br />
                    <div
                      style={{ width: '100%', borderBottom: '1px solid black' }}
                    ></div>
                    <br />
                    <br />
                  </BrowserView>

                  <div
                    className="show-only-desktop"
                    style={{ position: 'relative', zIndex: 1 }}
                  >
                    <div
                      className="title-description"
                      style={{ position: 'absolute', zIndex: '2' }}
                    >
                      <p>{translate.t('availability')}</p>
                    </div>
                    <div id="widget34725"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="widget-checkout-container">
            <div className="sticky-container">
              <BrowserView>
                <div className="checkout-book-now">
                  <div id="leadWidget" className=""></div>
                  <div className="card-check">
                    <div className="text-reserva">
                      <p style={{ fontSize: '12px', padding: '10px 0px' }}>
                        <strong>{translate.t('onlineBooking')}</strong>{' '}
                        {translate.t('onlineBookingText')}{' '}
                        <strong style={{ color: '#009984' }}>
                          (+54) 11 2522-9682
                        </strong>
                      </p>
                      <button
                        className="button-reserva btn-outline"
                        onClick={() => {
                          toggleModalContactProperty();
                        }}
                      >
                        {translate.t('contactUs')}
                      </button>
                      {showModalContactProperty && (
                        <ModalContactProperty
                          generateLead={generateLead}
                          property={property}
                        />
                      )}
                    </div>

                    <div className="checkout-cards mt-3">
                      <img
                        src={paypal}
                        width="100%"
                        height="100%"
                        alt="Loading..."
                      />
                      <img
                        src={visa}
                        width="100%"
                        height="100%"
                        alt="Loading..."
                      />
                      <img
                        src={master}
                        width="100%"
                        height="100%"
                        alt="Loading..."
                      />
                      <img
                        src={american}
                        width="100%"
                        height="100%"
                        alt="Loading..."
                      />
                    </div>
                  </div>
                </div>
              </BrowserView>
            </div>
          </div>
        </div>

        <div className="container"></div>

        <div className="location-map mt-5">
          <LocationMap zoom={15} properties={[property]} />
        </div>
      </div>

      <Footer />
    </div>
  );
}

function useScript(src) {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState(src ? 'loading' : 'idle');

  useEffect(
    () => {
      // Allow falsy src value if waiting on other data needed for
      // constructing the script URL passed to this hook.
      if (!src) {
        setStatus('idle');
        return;
      }

      // Fetch existing script element by src
      // It may have been added by another intance of this hook
      let script = document.querySelector(`script[src="${src}"]`);

      if (!script) {
        // Create script
        script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.setAttribute('data-status', 'loading');
        // Add script to document body
        document.body.appendChild(script);

        // Store status in attribute on script
        // This can be read by other instances of this hook
        const setAttributeFromEvent = (event) => {
          script.setAttribute(
            'data-status',
            event.type === 'load' ? 'ready' : 'error',
          );
        };

        script.addEventListener('load', setAttributeFromEvent);
        script.addEventListener('error', setAttributeFromEvent);
      } else {
        // Grab existing script status from attribute and set to state.
        setStatus(script.getAttribute('data-status'));
      }

      // Script event handler to update status in state
      // Note: Even if the script already exists we still need to add
      // event handlers to update the state for *this* hook instance.
      const setStateFromEvent = (event) => {
        setStatus(event.type === 'load' ? 'ready' : 'error');
      };

      // Add event listeners
      script.addEventListener('load', setStateFromEvent);
      script.addEventListener('error', setStateFromEvent);

      // Remove event listeners on cleanup
      return () => {
        if (script) {
          script.removeEventListener('load', setStateFromEvent);
          script.removeEventListener('error', setStateFromEvent);
        }
      };
    },
    [src], // Only re-run effect if script src changes
  );

  return status;
}

export default CheckoutScreen;

const CheckoutAmenitiesBox = ({ propertiesList, loading }) => {
  if (loading || propertiesList?.length === 0) return null;
  const property = propertiesList[0];
  const amenities = property.amenities;

  const hasWaher = amenities.find(
    (each) =>
      each.amenity === AMENITIES_LIST.HAS_WASHER ||
      each.amenity === AMENITIES_LIST.HAS_WATER_KETTLE,
  );
  const hasTv = amenities.find(
    (each) => each.amenity === AMENITIES_LIST.HAS_SMART_TV,
  );
  const hasWifi = amenities.find(
    (each) => each.amenity === AMENITIES_LIST.HAS_INTERNET_WIFI,
  );
  const hasAirConditioning = amenities.find(
    (each) => each.amenity === AMENITIES_LIST.HAS_AIR_CONDITIONING,
  );

  return (
    <>
      <div className="title-description checkout-mobile-container include-title-green">
        <p>{translate.t('equipment')}</p>
      </div>
      <div className="checkout-amenities-icons my-4">
        {hasWaher && (
          <div className="checkout-amenities-cont">
            <img src={washer} alt="lavarropas" />
            <span>Lavarropas</span>
          </div>
        )}

        {hasTv && (
          <div className="checkout-amenities-cont">
            <img src={tv} alt="tv" />
            <span>Smart TV</span>
          </div>
        )}

        {hasWifi && (
          <div className="checkout-amenities-cont">
            <img src={wifi} alt="high speed wifi" />
            <span>High Speed wifi</span>
          </div>
        )}

        {hasAirConditioning && (
          <div className="checkout-amenities-cont">
            <img src={ac} alt="air conditioner" />
            <span>Aire acondicionado</span>
          </div>
        )}
      </div>
    </>
  );
};
