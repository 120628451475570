import { propsPerPage } from '../Views/Properties';
import Helper, { Variables } from './Helper';
import { AMENITIES_NAMES } from './amenities/amenities';
import { FilterKeysNames, TRecentParams } from './filter/FilterContainer';
import { IProperty } from './interfaces/hostfully.property.interface';

export type AmenityKey = keyof typeof AMENITIES_NAMES;

export const HELPER_TSX = {
  goToPartners: function () {
    window.location.href = '/partners';
  },
  fromAmenitieIndexToName: function (amenitieindex: AmenityKey) {
    return AMENITIES_NAMES[amenitieindex];
  },
  prepareRouteCheckout: function (property: IProperty, filters: any) {
    let strParams = '';

    if (filters?.minimumGuests) strParams += `&guests=${filters.minimumGuests}`;
    if (filters?.checkInDate) strParams += `&checkIn=${filters.checkInDate}`;
    if (filters?.checkOutDate) strParams += `&checkOut=${filters.checkOutDate}`;
    return '/checkout?propertyId=' + property.uid + strParams;
  },
  getRecentParams: function (locationParams: any) {
    const page = parseInt(Helper.getParam('page', locationParams)) || 1;
    const kind = Helper.getParam('typeShow', locationParams);
    const favSelected = kind === FilterKeysNames.favorites;
    const params: TRecentParams = {
      checkInDate: Helper.getParam('checkIn', locationParams),
      checkOutDate: Helper.getParam('checkOut', locationParams),
      location: Helper.getParamNew('location', locationParams),
      minimumGuests: Helper.getParam('guests', locationParams) || 1,
      typeProperties: Helper.getParam('typesProperties', locationParams),
      customAmenities: Helper.getParam('customAmenities', locationParams),
      minimumBathrooms: Helper.getParam('minimumBathrooms', locationParams),
      minimumBedrooms: Helper.getParam('minimumBedrooms', locationParams),
      orderBy: Helper.getParam('orderBy', locationParams),
      kindOfProperties: '',
      page: page,
      limit: Variables.regularLimit,
      offset: (page - 1) * propsPerPage,
      favorites: favSelected,
    };

    return params;
  },
};
