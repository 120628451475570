export const AMENITIES_LIST = {
  HAS_LAUNDRY: 'HAS_LAUNDRY',
  HAS_SHARED_WASHER: 'HAS_SHARED_WASHER',
  HAS_BARBECUE: 'HAS_BARBECUE',
  HAS_COFFEE_MAKER: 'HAS_COFFEE_MAKER',
  HAS_HANGERS: 'HAS_HANGERS',
  HAS_DECK_PATIO: 'HAS_DECK_PATIO',
  HAS_CROCKERY_CUTLERY: 'HAS_CROCKERY_CUTLERY',
  HAS_GYM: 'HAS_GYM',
  HAS_CONTACTLESS_CHECKIN: 'HAS_CONTACTLESS_CHECKIN',
  HAS_HEATED_POOL: 'HAS_HEATED_POOL',
  HAS_SMART_TV: 'HAS_SMART_TV',
  HAS_WATER_KETTLE: 'HAS_WATER_KETTLE',
  HAS_KITCHEN: 'HAS_KITCHEN',
  HAS_24_CHECKIN: 'HAS_24_CHECKIN',
  HAS_MASSAGE_ROOM: 'HAS_MASSAGE_ROOM',
  HAS_FRIDGE: 'HAS_FRIDGE',
  HAS_POOL: 'HAS_POOL',
  HAS_GARDEN: 'HAS_GARDEN',
  HAS_HEATING: 'HAS_HEATING',
  HAS_OVEN: 'HAS_OVEN',
  HAS_INTERNET_WIFI: 'HAS_INTERNET_WIFI',
  HAS_AIR_CONDITIONING: 'HAS_AIR_CONDITIONING',
  HAS_TV: 'HAS_TV',
  HAS_HAIR_DRYER: 'HAS_HAIR_DRYER',
  HAS_JACUZZI: 'HAS_JACUZZI',
  HAS_WASHER: 'HAS_WASHER',
  HAS_LAUNDROMAT_NEARBY: 'HAS_LAUNDROMAT_NEARBY',
};

export const AMENITIES_NAMES = {
  HAS_LAUNDRY: 'Laundry',
  HAS_SHARED_WASHER: 'Lavadora',
  HAS_BARBECUE: 'Parrilla',
  HAS_COFFEE_MAKER: 'Cafetera',
  HAS_HANGERS: 'Perchas',
  HAS_DECK_PATIO: 'Deck',
  HAS_CROCKERY_CUTLERY: 'Vajilla',
  HAS_GYM: 'Gym',
  HAS_CONTACTLESS_CHECKIN: 'Auto checkin',
  HAS_HEATED_POOL: 'Pileta climatizada',
  HAS_SMART_TV: 'TV',
  HAS_WATER_KETTLE: 'Calentador de agua',
  HAS_KITCHEN: 'Cocina',
  HAS_24_CHECKIN: '24hs check in',
  HAS_MASSAGE_ROOM: 'Sala de masajes',
  HAS_FRIDGE: 'Freezer',
  HAS_POOL: 'Piscina',
  HAS_GARDEN: 'Jardín',
  HAS_HEATING: 'Calefacción',
  HAS_OVEN: 'Horno',
  HAS_INTERNET_WIFI: 'Wifi',
  HAS_AIR_CONDITIONING: 'Aire Acondicionado',
  HAS_TV: 'TV',
  HAS_HAIR_DRYER: 'Secador de pelo',
  HAS_JACUZZI: 'Jacuzzi',
  HAS_WASHER: 'Lavadora',
  HAS_LAUNDROMAT_NEARBY: 'Laundry',
};
